@import 'src/styles/core';

.templates-header {
  align-items: center;
  display: flex;
  height: $header-height;
  justify-content: space-between;
  z-index: 1;
  background-color: #fff;
  padding: 0 16px 0 0;

  &__logo {
    width: $sidebar-width;
    margin-right: 50px;
    display: flex; }

  &__info-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    &__ver {
      font-size: 11px;
      margin-right: 8px;
      font-weight: bold; } } }
