@import 'src/styles/core.sass';

.subscription-plan-edit-modal {
    &__content {
        &__form-title {
            margin: 0 0 16px;
            padding: 0 0 16px;
            border-bottom: 1px solid $grey-3;
            text-transform: capitalize; }

        .dnd-form-control {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px; }
        .dnd-toggler {
            margin: 0; }
        .dnd-switch__label {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            width: 100%; }
        .dnd-input__input[type="number"] {
            width: 80px; } }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px; } }
