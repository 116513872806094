@import 'src/styles/core.sass';

.new-category-item {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  .dnd-input__after .icon {
    margin-right: 0;
    font-size: 12px; }

  .dnd-icon-button {
    margin-right: 8px; }

  .dnd-form-helper-text {
    margin-left: 10px; } }
