@import 'src/styles/core.sass';

.dashboard-tour {
  background-color: #fff;
  display: flex;

  &__left {
    background-color: $blue-1;
    color: #fff;
    padding: 32px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column; }

  &__right {
    color: $purple-2;
    padding: 32px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column; }

  &__title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin: 0 0 24px; }

  &__description {
    font-size: 14px;
    line-height: 26px;
    flex-grow: 1;
    margin-bottom: 60px; }

  &__article:not(:last-child) {
    margin-bottom: 32px; }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end; }

  &__create-new.dnd-button-base.contained {
    background: #fff;
    color: $blue-1; }

  &__button-label {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 12px; } }

  &.step-2 {
    .dashboard-tour__left {
      .dashboard-tour__title {
        font-weight: 700;
        margin-top: 60px;
        text-align: center; } } }

  &.step-3 {
    .dashboard-tour__title {
      text-align: center; }

    .dashboard-tour__description {
      margin-top: 20px;
      text-align: center;
      margin-bottom: 12px; }

    .dashboard-tour__buttons-wrapper {
      justify-content: center; } } }
