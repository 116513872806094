@import 'src/styles/core.sass';

.template-settings-modal {
  &__content {
    & > .dnd-form-control {
      margin-bottom: 24px; } }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    .dnd-button {
      &:not(:last-child) {
        margin-right: 8px; } } } }
