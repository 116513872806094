@import 'src/styles/core.sass';

.base-popover {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  border-radius: $base-border-radius;
  &__content-wrapper {
    padding: 0 26px 26px; }

  &__header {
    color: $purple-2;
    margin-bottom: 20px; } }
