@import 'src/styles/core.sass';

.block-modal {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    &__category {
      .dnd-form-control {
        background: $gold-1;
        padding: 16px;
        border-radius: $base-border-radius;
        .dnd-select__select {
          background-color: #fff;
          color: $black-1; } } }
    &__inner {
      width: 48%;
      display: flex;
      flex-direction: column;
      min-height: 200px;
      justify-content: space-between; }
    &__inner-full {
      width: 100%;
      width: 100%;
      flex-direction: row;
      min-height: auto;
      .dnd-form-control.fullWidth {
        width: 48%; } } }

  &__editor-wrapper {
    width: 48%; }

  &__editor {
    font-size: 12px;
    .CodeMirror {
      height: 200px; }
    @include code-mirror-search; }


  &__preview {
    width: 100%;
    height: 300px;
    border: none; }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px; }

  &__button {
    &:not(:last-child) {
      margin-right: 8px; } } }
