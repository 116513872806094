@import 'src/styles/core.sass';

.upgrade-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 14px;
    line-height: 1.6;
    margin: 0;
    padding: 24px;
    text-align: center; }
  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%; } }
