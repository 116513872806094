@import 'src/styles/core.sass';

.dnd-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $black-2;
  font-weight: 700;
  margin-left: 16px;
  a {
    color: $black-2;
    @include event {
      color: $black-2;
      text-decoration: underline; } }
  &__template-status {
    text-transform: capitalize; }
  &__separator {
    margin: 0 6px;
    .icon {
      font-size: 14px !important; } } }
