@import 'src/styles/core.sass';

.dnd-button-base {
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  font: 400 12px/14px $base-font-family;
  color: $default-btn-color;
  background-color: transparent;
  @include event {
    background-color: transparent; }

  .dnd-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; }

  .dnd-button__spinner-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: inherit; }

  &.primary {
    color: $primary-btn-color; }

  &.secondary {
    color: $secondary-btn-color; }

  &.contained {
    color: #fff;
    background-color: $default-btn-color;

    @include event {
      box-shadow: none; }

    &:disabled {
      box-shadow: none;
      cursor: not-allowed; }

    &.primary {
      background-color: $primary-btn-color; }

    &.secondary {
      background-color: $secondary-btn-color !important; } }

  &.outlined {
    box-shadow: $block-shadow;
    background-color: #fff;
    border: 1px solid $default-btn-color;
    color: $default-btn-color;
    &:not(:disabled) {
      @include event {
        background-color: $default-btn-color;
        color: #fff; } }

    &.primary {
      border: 1px solid $primary-btn-color;
      color: $primary-btn-color;
      &:not(:disabled) {
        @include event {
          background-color: $primary-btn-color;
          color: #fff; } } }

    &.secondary {
      border: 1px solid $secondary-btn-color;
      color: $secondary-btn-color;
      &:not(:disabled) {
        @include event {
          background-color: $secondary-btn-color !important;
          color: #fff; } } } } }
