@import 'src/styles/core.sass';

.new-template-item {
  position: relative;
  width: 100%;
  min-height: 285px;

  &__wrapper {
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    background-color: #fff;
    border-radius: $base-border-radius;
    cursor: pointer;
    display: flex;
    justify-content: center;

    .icon {
      display: block;
      margin-right: 10px;
      font-size: 12px; } } }
