@import 'src/styles/core.sass';

.code-mirror-all-buttons {
  display: flex;
  button.code-mirror-all-buttons__copy-all,
  button.code-mirror-all-buttons__paste-all {
    margin-right: 10px;
    background: transparent;
    color: $black;
    border: 1px solid $black;
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.5; } }

  &__upload-button {
    position: relative;
    display: flex;
    margin-right: 15px;
    &__input[type="file"] {
      width: 130px;
      color: transparent;
      overflow: hidden;
      margin: 0;
      padding: 0;
      &::-webkit-file-upload-button {
        visibility: hidden; }
      &:before {
        content: 'Select file';
        display: inline-block;
        background: #fff;
        color: $black;
        border: 1px dashed $grey-6;
        font-size: 12px;
        padding: 10px 30px;
        transition: all 0.5s;
        cursor: pointer; }
      &:hover:before {
        border-color: $grey-8; } } } }
