@import 'src/styles/core.sass';

.dnd-input {
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $grey-5;
  border-radius: $base-border-radius;
  color: $grey-1;
  &.fullWidth {
    display: flex;
    width: 100%; }
  &.error {
    border-color: $red; }
  &.disabled {
    opacity: 0.7; }

  .dnd-input__input,
  .dnd-input__textarea {
    width: auto;
    flex-grow: 1;
    background-color: transparent;
    font: 400 12px/14px $base-font-family;
    color: $black-1;
    margin: 0;
    padding: 10px;
    transition: none;
    min-width: 30%;
    &::placeholder {
      color: $grey-9; }

    &:hover,
    &:active,
    &:focus {
      background-color: transparent; } }


  .dnd-input__textarea {
    min-height: 129px;
    resize: none; }


  &__before, &__after {
    display: flex;
    align-items: center;
    .icon {
      font-size: 16px; } }

  &__before {
    .icon {
      margin-left: 10px; } }

  &__after {
    .icon {
      margin-right: 10px; } } }
