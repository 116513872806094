@import 'src/styles/core';

.templates-filters {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 30px 60px 15px 50px;
    background: $grey-3;
    z-index: 2;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &__title {
        text-transform: uppercase;
        font-size: 32px;
        font-weight: 700;
        color: $black;
        margin-bottom: 10px;
        .icon {
            font-size: 28px;
            margin-right: 10px; } }
    .create-new,
    .delete-forever {
        background: $purple-2;
        font-size: 14px;
        color: #fff;
        margin-left: 16px;
        padding: 14px 21px;
        .icon {
            margin-left: 16px; } }
    .delete-forever {
        background: $delete-btn-color; }
    &__actions {
        flex: 1;
        background: #fff;
        border-radius: $base-border-radius;
        height: 48px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__search-wrapper {
            position: relative; }
        &__clear-search {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            font-size: 14px;
            cursor: pointer;
            color: $grey-7;
            padding: 0;
            margin: 0; }
        &__sort {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            .dnd-select-wrapper {
                padding-right: 15px; }
            .dnd-label {
                margin: 0;
                color: $grey-7; }
            .dnd-select__select {
                color: $grey-7;
                border: none;
                padding-left: 5px; } }
        &__search {
            width: 320px;
            .dnd-input {
                width: 100%;
                background-color: $grey-3;
                border: none; } } } }

@media (max-width: 1200px) {
    #layout .home .templates-wrapper {
        padding-top: 370px; }
    .templates-filters {
        padding-top: 30px;
        &__actions {
            order: 1;
            flex-direction: column;
            align-items: stretch;
            padding: 20px;
            width: 100%;
            margin-bottom: 16px;
            &__sort {
                justify-content: flex-start; }
            &__search {
                width: 100%;
                margin-bottom: 8px; } }
        .bulk-actions {
            &__bulk-button {
                margin: 0 0 8px 0; } }
        .templates-view {
            &__action {
                margin: 0; } }
        &__inner {
            flex-direction: column;
            align-items: flex-start; }
        .create-new,
        .delete-forever {
            order: 0;
            margin: 16px 0; } } }

@media (max-width:997px) {
    .templates-filters {
        &__title {
            font-size: 22px; }
        .create-new,
        .delete-forever {
            margin: 16px 0;
            width: 100%; } } }
