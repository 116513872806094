@import 'src/styles/core.sass';

.user-permissions-modal {
  &__content {
    margin: 1rem 0 2rem;
    overflow-y: scroll;
    max-height: 350px;
    padding: 0 10px 0 0;
    &__item {
      display: flex;
      align-items: center;
      border-bottom: 2px solid $grey-3;
      padding-bottom: 14px;
      margin-bottom: 14px;
      font-size: 14px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0; }
      label {
        margin: 0 0 0 1rem; }
      span {
        flex: 1; } } }
  &__buttons {
    display: flex;
    justify-content: flex-end; } }
