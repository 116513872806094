@import 'src/styles/core.sass';

.dnd-image-item {
  position: relative;
  width: calc(25% - 20px);
  margin: 10px;
  cursor: pointer;
  line-height: 0;

  &__image-name {
    font-size: 11px;
    font-weight: normal;
    padding: 0;
    margin: 12px 0 2px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

  &__item-author {
    margin: 16px 0 0 0;
    display: block;
    font-size: 11px;
    text-transform: capitalize;
    a {
      margin-left: 4px;
      color: $blue-1; } }

  &.selected img {
    outline: 10px solid lighten($blue-1, 20%); }

  &.icon {
    width: 64px;
    height: 64px;
    overflow: hidden; }

  &__delete-button {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    background-color: #fff;
    color: $grey-1;
    &:hover {
      background-color: $red;
      color: #fff; }
    &:focus {
      background-color: #fff; } }

  &__image {
    width: 100%;
    height: 120px;
    object-fit: cover;
    &:hover {
      outline: 10px solid $blue-2; }
    &.icon {
      width: 64px;
      height: 64px; } } }
