@import 'src/styles/core.sass';

.dnd-fonts-select {
  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid $grey-2;
    background-color: transparent;
    border-radius: $base-border-radius;
    color: $grey-1;
    font-size: 12px;
    padding: 9px;
    @include event {
      background-color: transparent; } }

  &__list {
    position: absolute;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    box-shadow: $block-shadow;
    display: none;
    &.isOpen {
      display: block; }

    &-item {
      &.highlighted {
        background-color: $blue-3; }
      &.selected {
        background-color: $blue-2; } } } }
