@import 'src/styles/core.sass';

.switch-users-modal {
  &__user-item {
    display: flex;
    align-items: center;
    border: 1px solid $grey-4;
    border-radius: $base-border-radius;
    padding: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.5s;
    user-select: none;
    &:hover:not(.current-user) {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px 0px; }
    &:focus {
      outline-color: $purple-2; }
    &.current-user {
      background: $grey-3;
      cursor: unset; }
    &__avatar {
      img {
        border-radius: 50%;
        width: 64px;
        margin-right: 16px; } }
    &__details {
      h3 {
        padding: 0;
        margin: 0 0 2px;
        font-weight: 500;
        color: $black-2; }
      &__role {
        font-size: 11px;
        margin: 0 0 4px;
        color: $grey-1; }
      &__email {
        font-size: 12px;
        color: $grey-8; } } } }
