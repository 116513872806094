@import 'src/styles/core.sass';

.dnd-images-content {
  &__load-more {
    text-align: center;
    color: currentColor;
    width: 100%;
    height: 40px;
    text-transform: uppercase;
    font: 700 12px/40px "Arial", sans-serif; }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .icon {
      font-size: 48px; } }

  &__image-items {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start; } }
