@import 'src/styles/core.sass';

.dnd-user-menu {
  &__list {
    width: 240px;
    hr {
      margin: 0; } }
  &__title {
    margin: 0;
    padding: 5px 20px;
    color: $grey-1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700; }
  &__current-plan {
    text-transform: capitalize;
    &__plan {
      flex: 1;
      margin-left: 5px;
      font-weight: bold; } }
  &__item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      color: $grey-1;
      font-size: 12px; } } }
