@import 'src/styles/core';

.templates-view {
  &__action {
    display: flex;
    align-items: center;
    margin-left: 16px;
    &__item {
      &:nth-child(1) > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      &:nth-child(2) > button {
        border-radius: 0;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff; }
      &:nth-child(3) > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      &__button {
        background: $grey-4;
        color: $grey-2;
        border-radius: $base-border-radius;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 0.9;
          background: $grey-4; }
        &:focus,
        &.active {
          background-color: $purple-2;
          color: #fff; }
        .icon {
          font-size: 14px; } } } } }
