@import 'src/styles/core.sass';

.confirm-modal {
  color: $black;

  &__content {
    margin-bottom: 40px; }

  &__buttons {
    display: flex;
    justify-content: flex-end; }

  &__button {
    &:not(:last-child) {
      margin-right: 8px; } } }
