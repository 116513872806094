[data-reach-tab-list] {
  background: transparent;
  border-bottom: 1px solid $grey-8; }
[data-reach-tab] {
  border: none;
  padding: 8px 12px 10px;
  color: $grey-8;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: -1px;
  .icon {
    font-size: 14px;
    margin-right: 8px; }
  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: not-allowed; }
  @include event {
    background: hsla(0, 0%, 0%, 0.05);
    outline: none; }
  &[data-selected] {
    color: #fff !important;
    position: relative;
    background-color: $blue-1;
    .icon {
      color: #fff !important; }
    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      content: '';
      height: 2px;
      width: 100%;
      background-color: currentColor; } } }
[data-reach-tab-panel] {
  outline: none; }

[data-reach-tooltip] {
  z-index: 110; }

[data-reach-menu], [data-reach-menu-popover], [data-reach-popover] {
  z-index: 10; }
[data-reach-menu-list] {
  padding: 8px 0;
  font-size: 14px;
  border: none;
  box-shadow: $block-shadow; }
[data-reach-menu-item] {
  transition: 0.3s all ease;
  &[data-selected] {
    background-color: $grey-6;
    color: inherit; } }
