@import 'src/styles/core.sass';

.version-history-modal {
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px; }
  &__version {
    border-bottom: 1px solid $grey-3;
    font-size: 11px;
    padding: 16px 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $grey-8;
    transition: background 0.2s;
    cursor: pointer;
    box-shadow: rgba(33, 35, 38, 0.1) 1px 5px 10px -10px;
    &.active,
    &:hover {
      background: $blue-1;
      border-color: #fff;
      color: #fff;
      transition: opacity 0.8s;
      button {
        opacity: 1; }
      .icon,
      time,
      span {
        color: #fff; } }
    &__title {
      time {
        font-size: 14px;
        display: block;
        color: $black-1;
        margin-bottom: 4px; }
      &__user-name {
        font-size: 11px;
        color: $grey-9;
        text-transform: capitalize; } }
    &__actions {
      display: flex;
      align-items: center;
      button {
        margin: 0;
        padding: 0;
        border-radius: $base-border-radius;
        background: transparent !important;
        color: $grey-2;
        opacity: 0;
        font-size: 13px;
        &:has(.icon-visibility) {
          font-size: 16px; }
        &:last-child {
          margin-left: 14px; } } } }
  &__no-versions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $grey-2;
      font-size: 14px;
      height: 250px;
      span {
        display: inline-block;
        margin-bottom: 8px;
        font-size: 40px;
        color: $grey-2; } } }
