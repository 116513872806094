@import 'src/styles/core.sass';

.waymore-product-modal {
  &__header {
    color: $purple-2;
    margin-bottom: 20px; }

  &__content-wrapper {
    padding: 0 26px 26px;
    flex: 1 1;

    .dnd-form-control {
      &:not(:last-child) {
        margin-bottom: 24px; } } }

  &__options {
    display: flex;
    align-items: center;
    margin: 25px 0;
    .dnd-form-control {
      margin: 0 25px 0 0 !important; } }

  &__empty-content {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 20px;
    font-size: 13px;
    line-height: 22px;
    &-message {
      color: $black-2;
      text-align: center;
      a {
        color: $blue-1;
        &:hover, &:active, &:focus {
          color: $blue-1; } } } }

  &__product-item {
    display: flex;
    padding: 5px 0;
    &__img {
      width: 36px;
      height: 36px;
      object-fit: contain;
      margin-right: 10px; }
    &__info {
      margin-right: auto; }
    &__name {
      font-size: 14px;
      margin-bottom: 8px; }
    &__category {
      color: $grey-1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 380px; }
    &__price {
      font-size: 14px; } }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dnd-form-control {
        margin-bottom: 0 !important; }
    .dnd-button-base.contained {
      background: $black; } } }
