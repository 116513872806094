@import 'src/styles/core.sass';

.block-settings-box {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: $bg-color;
  color: $black;
  z-index: 3;
  border-top-left-radius: $base-border-radius;
  border-top-right-radius: $base-border-radius;
  transition: bottom 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  &__toggle {
    display: none; }
  &.hide {
    bottom: -95px;
    .block-settings-box__toggle {
      display: block;
      text-align: center;
      font: $font-icons;
      font-size: $base-border-radius;
      font-weight: bold;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translate(-50%);
      background: $bg-color;
      border-radius: 0;
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
      padding: 8px 12px 6px;
      margin: 0;
      cursor: pointer;
      &:hover,
      &:active,
      &:focus {
        background: $bg-color;
        &:before {
          color: $blue-1; } }
      &:before {
        content: '\e92d';
        transition: all 0.5s;
        font: $font-icons;
        font-size: 14px;
        color: $grey-7;
        cursor: pointer; } } }
  .icon-close {
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px; }
  &__header {
    position: relative;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    border-bottom: 1px solid $grey-5;
    p {
      margin: 0 0 0 8px;
      padding: 0; }
    & > div {
      display: flex;
      align-items: center; } }
  &__content {
    padding: 0 15px 15px; }
  &__horiz-buttons {
    display: flex;
    font-size: 12px;
    button {
      border-radius: 0;
      background: #fff;
      color: $blue-1;
      cursor: pointer;
      border-right: 1px solid $bg-color;
      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px; }
      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-right: none; }
      &.active,
      &:hover,
      &:focus {
        background: $blue-1;
        color: #fff; } } } }
