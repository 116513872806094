@keyframes loadingCircle {
  100% {
    transform: rotate(360deg); } }

@keyframes flip {
  35% {
    transform: rotateY(0); }
  45% {
    transform: rotateY(180deg); }
  85% {
    transform: rotateY(180deg); }
  95% {
    transform: rotateY(0); } }
