@import 'src/styles/core.sass';

.empty-content {
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__wrapper {
    width: 360px; }
  &__title {
    color: rgba($purple-2, 0.38);
    font: 700 28px/34px Segoe UI, sans-serif;
    margin: 0 0 20px; }
  &__subtitle {
    margin: 0;
    font: 400 16px/21px $base-font-family;
    color: $grey-1; } }
