@import 'src/styles/core.sass';

.published-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $black;

  .dnd-copy-box {
    margin: 10px 0 20px; }

  &__description {
    font-size: 12px;
    font-family: 'Helvetica Neue', sans-serif;

    a {
      color: $blue-1; } }

  &__link-wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;

    .icon-content-copy {
      color: $blue-1; } }

  &__link {
    margin: 8px;
    flex-grow: 1; }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .dnd-button {
      .icon-open_in_new {
        margin-left: 32px; }
      &:not(:last-child) {
        margin-right: 8px; } } } }
