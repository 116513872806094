@import 'src/styles/core.sass';

.dnd-select-box {
  &--error {
    .dnd-select-box__select__control {
      border-color: $red !important; }
    .dnd-form-helper-text {
      color: $red !important; } }
  &__label {
    display: flex; }
  &__tooltip {
    margin-left: 5px; }
  .dnd-select-box__select {
    font-size: 12px;
    &__single-value {
      margin-left: 4px;
      margin-right: 4px; }
    &__clear-indicator {
      color: $black;
      cursor: pointer;
      padding: 8px 4px 8px 8px;
      svg {
        display: none; }
      &:before {
        content: "\e917";
        font: $font-icons;
        font-size: 14px; } }
    &__input-container {
      grid-template-columns: 0 1fr; }
    &__input[type=text] {
      border-radius: 0;
      &::placeholder {
        color: $grey-1;
        padding-left: 5px; } }
    &__loading-indicator {
      &:before {
        content: "\e900";
        font: $font-icons;
        font-size: 16px;
        animation: loadingCircle 1s infinite linear; }
      span {
        display: none; } }
    &__dropdown-indicator {
      padding: 0 10px;
      color: $black-1;
      &:before {
        content: "\e904";
        font: $font-icons;
        font-size: 16px; }
      &:hover {
        color: $black-1; }
      svg {
        display: none; } }
    &__option {
      color: $black;
      font-size: 11px;
      padding: 5px 6px;
      &:hover {
        background: $blue-3; } }
    &__placeholder {
      color: $grey-1;
      margin-left: 5px; }
    &__value-container {
      padding: 0 0 0 3px; }
    &__control {
      border-color: $grey-5;
      &:hover,
      &--is-focused {
        border-color: $grey-5;
        box-shadow: none; } }
    &__multi-value {
      border-radius: $base-border-radius;
      &__remove {
        background: $blue-2;
        color: $black;
        padding: 0 5px;
        cursor: pointer;
        border-radius: $base-border-radius;
        &:before {
            content: "\e917";
            font: $font-icons;
            font-size: 16px; }
        &:hover {
          background: $blue-2;
          color: $black; }
        svg {
          display: none; } }
      &__label {
        background-color: $blue-2;
        color: $black;
        font-size: 12px;
        font-weight: normal;
        padding: 5px; } } } }
