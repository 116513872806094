@import 'src/styles/core.sass';

$switch-height: 28px;
$switch-width: $switch-height * 2;
$handle-size: $switch-height - 2;

.dnd-toggler {
  position: relative;
  display: inline-block;
  height: $switch-height;
  border-radius: $switch-height / 2;
  margin-right: 8px;
  overflow: hidden;

  &__background {
    height: $switch-height;
    width: $switch-width;
    background-color: $grey-2;
    position: relative;
    transition: 0.25s background-color ease;
    &.checked {
      background-color: $blue-1; } }

  &__handle {
    position: absolute;
    top: 1px;
    left: 1px;
    height: $handle-size;
    width: $handle-size;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.25s transform ease;
    &.checked {
      transform: translateX($switch-width / 2); } }

  &__input {
    opacity: 0;
    visibility: hidden; } }
