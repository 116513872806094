@import 'src/styles/core.sass';

.template-qr-modal {
  &__qr-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 292px;
    height: 292px;
    margin: 0 auto;
    border: 2px solid $grey-5;
    padding: 16px;
    border-radius: 8px; }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px; } }
