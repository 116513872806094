@import 'src/styles/core.sass';

.dnd-text-field-tags {
  .dnd-input__before {
    flex-wrap: wrap; }
  .dnd-tag {
    margin: 2px 0 2px 5px;
    span {
      text-overflow: ellipsis;
      white-space: pre-wrap;
      max-width: 200px;
      overflow: hidden;
      display: block;
      line-height: 18px; } } }
