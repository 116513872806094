@import 'src/styles/core.sass';

@keyframes rotate {
  40% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  90% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

.page-settings-modal {
  color: $purple-2;
  display: flex;
  flex-direction: column;

  &__sub-title {
    font-size: 14px;
    padding: 0;
    margin: 0 0 16px; }
  .dnd-checkbox {
    &__icon-wrapper {
      color: #000;
      width: 20px;
      height: 20px; }
    &__icon {
      border-radius: 2px;
      &.checked {
        &:after {
          height: 10px; } } } }

  a {
    color: $blue-1; }

  &__form {
    display: flex;
    flex-direction: column; }

  &__content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }

  &__tab-panels {
    flex-grow: 1;
    margin: 30px 0; }

  &__lock-status {
    align-items: center;
    background: $grey-3;
    padding: 10px;
    border-radius: $base-border-radius;
    width: 50%;

    div[data-id="tooltip"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
      padding: 1rem 2rem;
      color: $grey-1;
      font-size: 12px;

      &.show {
        opacity: 1; }
      .icon {
        font-size: 22px;
        width: 45px;
        height: 45px; }
      button {
        border-radius: $base-border-radius;
        background: $blue-1;
        padding: 7px 20px; } }
    &__icon {
      .icon {
        background-color: $red-2;
        color: $red-1;
        display: flex;
        width: 30px;
        height: 30px;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 50%; } } }

  &__row {
    display: flex;
    justify-content: space-between;
    opacity: 1;
    visibility: visible;
    &.align-bottom {
      align-items: flex-end; }

    &:not(:last-child) {
      margin-bottom: 16px; }

    & > .dnd-form-control {
      &:not(:last-child) {
        margin-right: 16px; } } }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;

    .dnd-button {
      &:not(:last-child) {
        margin-right: 8px; } } }

  &__forms {
    display: flex;
    flex-wrap: wrap;
    #enable-send-email {
      width: 400px;
      padding: 0px 18px; }
    .enable-send-email {
      display: flex;
      align-items: center;
      font-size: 12px;
      .icon {
        color: $black-1;
        margin-bottom: 8px; }
      a {
        margin: 0 5px 8px 5px; }
      .dnd-form-control.fullWidth {
        width: auto; } }
    &__policy-options {
      .dnd-radio-button__label {
        padding-right: 20px;
        padding-left: 20px; } }
    &__checkbox {
      width: 100%;
      background-color: $grey-3;
      padding: 16px;
      border-radius: $base-border-radius;
      .dnd-checkbox__label {
        margin-bottom: 20px; }
      &__control {
        &--lock {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dnd-checkbox__label {
            margin-bottom: 0; } } }
      &__fields {
        display: flex;
        & > :last-child {
          margin-left: 8px; } }
      .dnd-input {
        background: #fff; } }
    .email-template-preview {
      width: 100%;
      .dnd-form-control.fullWidth {
        margin-bottom: 8px; }
      &__frame {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        background: $grey-3;
        height: 295px;
        border-radius: $base-border-radius;
        opacity: 1;
        background: repeating-linear-gradient(-45deg, #fff, #fff 2px, $grey-3 2px, $grey-3 10px);
        img {
          position: absolute;
          bottom: -85px;
          width: 280px;
          box-shadow: 0 2px 6px rgba(0,0,0,0.5);
          transition: bottom 2s;
          &:hover {
            bottom: 0;
            transition: all 2s; } }
        &:empty {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          &:before {
            content: 'Template preview'; } } } }
    &__full-col {
      width: 100%;
      margin-bottom: 16px; }
    &__col {
      width: 50%;
      flex: 1;
      &:last-child {
        margin-left: 16px; } } }

  &__social {
    &-inputs {
      margin-right: 16px;
      .dnd-form-control {
        &:not(:last-child) {
          margin-bottom: 30px; } } }

    .dnd-input__textarea {
      min-height: 54px; }

    .dnd-cropper {
      flex: 0 0 480px; } }

  &__schedule {
    .dnd-form-control {
      margin-bottom: 16px !important; }
    &__date-range {
      width: 100%;
      display: flex;
      .dnd-form-control {
        &:not(:last-child) {
          margin-right: 16px; } } } }

  &__radio-item-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Tight, .Average, .Narrow {
      position: relative;
      background-color: $blue-1;
      height: 1px;
      width: 24px;
      margin-left: 12px;
      &::after, &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $blue-1; } }
    .Tight::after {
      top: -4px; }
    .Tight::before {
      top: 4px; }
    .Average::after {
      top: -6px; }
    .Average::before {
      top: 6px; }
    .Narrow::after {
      top: -8px; }
    .Narrow::before {
      top: 8px; } }


  &__seo {
    &-select {
      min-width: 320px; } }

  &__page {
    .dnd-cropper {
      flex: 0 0 400px;
      &:not(:last-child) {
        margin-right: 90px; } } }

  &__domain {
    font-size: 12px;

    .dnd-copy-box {
      margin-top: 10px; }

    &-link-wrapper {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $black;
      .icon {
        margin-right: 8px; } }

    &-published-url {
      width: 100%;
      margin-right: 40px;
      color: $black; }

    &-input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }

    &-name-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }

    &-name {
      flex-grow: 1;
      background-color: $grey-6;
      border-radius: $base-border-radius;
      padding: 10px 16px;
      color: $black; }

    &-status-chip {
      width: 40px;
      margin-left: 8px;
      padding: 10px;
      text-align: center;
      font: 400 12px/14px $base-font-family;
      background-color: $grey-2;
      color: #fff;
      border-radius: $base-border-radius;
      &.loading {
        background-color: $blue-1;
        .icon-hourglass-empty {
          animation: rotate 5s ease-in-out infinite; } }
      &.pending {
        background-color: $grey-1; }
      &.success {
        background-color: $green; }
      &.error {
        background-color: $red; } }

    &-button {
      width: 160px;
      margin-left: 8px; }

    &-info {
      width: 100%;

      &__table {
        margin: 10px 0 0;
        width: 100%;
        border-spacing: 0;
        font-size: 14px;
        .icon {
          font-size: 100%;
          margin-left: 8px; } }

      &__table-head {
        background-color: $grey-2;
        text-transform: uppercase;
        color: #fff; }

      &__table-body {
        background-color: $grey-3;
        color: $black; }

      &__table-item {
        padding: 20px 0;
        font-weight: 400; } } } }
