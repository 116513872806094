@import 'src/styles/core.sass';

.preview-header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  z-index: 11;
  background-color: $header-bg-color;

  &__right-col {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-content: center; }

  &__devices {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

  .dnd-logo {
    width: $logo-size;
    height: $logo-size; }

  .dnd-breadcrumb {
    color: #fff;
    a {
      color: #fff; } }

  &__exit-preview-btn {
    margin-right: 18px;
    &.dnd-button-base {
      background: $purple-1;
      padding: 6px 14px;
      height: 25px;
      font-weight: 500;
      .icon {
        margin-left: 10px; } } }

  &__size-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    font-size: 18px;
    color: #fff;
    margin: 0;
    padding: 0 15px;
    background-color: transparent;
    border-radius: 0;
    &.active {
      background-color: $blue-1; } } }
