@import 'src/styles/core.sass';

.svg-modal {
  color: $purple-2;

  &__content {
    display: flex;
    flex-direction: column; }

  &__images-wrapper {
    margin-top: 35px;
    height: 350px;
    overflow-x: auto;
    border-radius: $base-border-radius;
    background-color: $grey-3; } }
