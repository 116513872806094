@import 'src/styles/core.sass';

.category-menu {
  &__popover {
    width: 650px; }
  &__menu-list[data-reach-menu-list] {
    border-radius: $base-border-radius;
    min-width: 160px;
    padding: 0;
    font-size: 12px;
    color: $black;
    .category-menu__menu-list--delete {
      color: $red; }
    div[data-reach-menu-item] {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      border-bottom: 1px solid $grey-3;
      @include event {
        background: $grey-3;
        border-radius: $base-border-radius; }
      .icon {
        margin-right: 12px; }
      &:last-child {
        border-bottom: none; } } } }
