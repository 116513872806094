@import 'src/styles/core.sass';

.subscription-plans-modal {
    display: flex;
    flex-direction: column;
    &__plans-list {
        margin: 16px 0 0;
        overflow-y: scroll;
        max-height: 350px;
        padding: 0 10px 0 0;
        &__title {
            span {
                font-size: 12px;
                margin-left: 4px;
                text-transform: capitalize;
                color: lighten(#000, 40%); } }
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            padding-bottom: 8px;
            &:empty {
                border-top: 2px solid $grey-3;
                margin-top: 8px;
                padding-top: 8px; }
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0; } }
        &__create-btn {
            align-self: flex-end;
            .icon {
                font-size: 10px; } } } }
