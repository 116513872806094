@import 'src/styles/core.sass';

.waymore-calendar-modal {
  &__header {
    margin-bottom: 20px;
    color: $purple-2; }

  &__content-wrapper {
    flex: 1 1;
    padding: 0 26px 26px;

    .dnd-form-control {
      margin-bottom: 24px; } }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    .dnd-button-base.contained {
        background: $black; } } }
