@import 'src/styles/core.sass';

$collapse-buttons-size: 24px;
$sidebar-offset: $collapse-buttons-size / 2;

.editor-content {
  height: calc(100vh - #{$header-height});
  background: $bg-color;
  display: flex;

  .component-settings {
    & + #containerStyle > .div-style > .gjs-clm-tags,
    .gjs-trt-traits {
      display: none; }
    &.open {
      & + #containerStyle > .div-style > .gjs-clm-tags,
      .gjs-trt-traits {
        display: block; }
      .component-settings__header {
        &:after {
          @include pseudo-expand-less; } } }
    &__header {
      position: relative;
      user-select: none;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 11px;
      background: $grey-3;
      border-top: 1px solid $grey-4;
      border-bottom: 1px solid $grey-4;
      padding: 11px 16px;
      display: flex;
      align-items: center;
      color: $black;
      font-weight: bold;
      &:before {
        content: "\e92d";
        font-family: 'icons';
        font-size: 16px;
        color: $purple-1;
        margin-right: 10px; }
      &:after {
        @include pseudo-expand-more;
        position: absolute;
        right: 16px; } } }
  &__blocks {
    position: relative;
    width: $editor-sidebar-width;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    z-index: 5;
    .custom-module-preview {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: #fff;
      box-shadow: 0 6px 12px rgba(0,0,0, 0.18);
      border: 1px solid $grey-2;
      width: 250px;
      border-radius: 10px;
      padding: 20px;
      z-index: 0;
      opacity: 0;
      &__show {
        transition: opacity 0.2s;
        opacity: 1;
        right: -250px; }
      &__divider {
        margin: 0 -20px 15px;
        padding: 0 0 10px;
        border-bottom: 1px solid $grey-5; }
      &__title {
        font-size: 14px;
        padding: 0;
        margin: 0 0 10px; }
      &__description {
        font-size: 12px;
        padding: 0;
        margin: 0; } }
    &.collapsed {
      margin-left: -$editor-sidebar-width + $sidebar-offset;
      &::after {
        right: -$collapse-buttons-size; }
      &:hover {
        transform: translateX($editor-sidebar-width - $sidebar-offset); } }
    .editor-content__collapse-button {
      right: 0;
      transform: translateX(50%); } }

  &__blocks-list-wrapper {
    padding: 8px 0;
    background-color: #fff;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    z-index: 3;

    &__top-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      padding: 10px 16px; }

    &__creat-group-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        font-size: 14px;
        margin-left: 10px;
        width: 20px;
        height: 20px; }
      .icon {
        font-size: 10px; } } }

  &__blocks-list {
    flex: 1 1;
    overflow: auto; }

  &__blocks-search-input {
    background-color: #fff;
    margin: 10px 15px 15px;
    border-color: $grey-5; }

  &__canvas-wrapper {
    display: flex;
    flex-direction: column;
    margin: 16px $grid-gap 8px;
    flex: 1 1; }

  &__canvas-actions {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &__canvas {
    margin-top: 16px;
    flex: 1 1;
    overflow: hidden; }

  &__traits {
    position: relative;
    display: flex;
    flex-direction: column;
    width: $editor-traits-sidebar-width;
    transition: all 0.3s ease;
    z-index: 5;
    &.collapsed {
      margin-right: -$editor-traits-sidebar-width + $sidebar-offset;
      &::after {
        left: -$collapse-buttons-size; }
      &:hover {
        transform: translateX(-$editor-traits-sidebar-width + $sidebar-offset); } }
    .editor-content__collapse-button {
      left: 0;
      transform: translateX(-50%); } }

  &__collapse-button {
    position: absolute;
    padding: 0;
    width: $collapse-buttons-size;
    height: $collapse-buttons-size;
    border-radius: 50%;
    z-index: 4;
    top: 60px; }

  &__blocks, &__traits {
    &.collapsed {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: $collapse-buttons-size;
        height: 100%; }
      .editor-content__collapse-button {
        opacity: 1;
        visibility: visible; } }
    .editor-content__collapse-button {
      opacity: 0;
      visibility: hidden; }
    &:hover {
      .editor-content__collapse-button {
        opacity: 1;
        visibility: visible; } }
    &.disabled {
      pointer-events: none; } } }
