@import 'src/styles/core.sass';

.dnd-radio-button {
  &__label {
    display: inline-block;
    padding: 10px;
    background-color: $blue-3;
    color: $blue-1;
    border-radius: $base-border-radius;
    font: 500 12px/14px $base-font-family;
    cursor: pointer;
    margin: 0;
    transition: all 0.5s;
    &__description {
      font-weight: 400;
      font-size: 10px;
      margin: 5px 0 0;
      padding: 0;
      font-style: italic; }
    &:hover,
    &.active {
      background-color: $blue-2; }
    &:not(:last-child) {
      margin-right: 10px; } }
  &__input {
    display: none !important; } }
