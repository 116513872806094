@import 'src/styles/core.sass';

.dnd-copy-box {
  background-color: $grey-6;
  border-radius: $base-border-radius;
  padding: 5px 15px;
  cursor: pointer;
  position: relative;

  &.fullWidth {
    width: 100%; }

  @include event {
    outline: none;
    background-color: darken($grey-6, 5%); }

  &.disabled {
    cursor: default;
    @include event {
      background-color: $grey-6; } }

  &__tooltip {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    background-color: $green;
    color: #fff;
    padding: 10px 15px;
    border-radius: $base-border-radius;
    font-size: 12px;
    pointer-events: none;
    z-index: 20;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $green; } } }
