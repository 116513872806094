@import 'src/styles/core.sass';

.template-sharing-modal {
  &__content {
    margin-bottom: 40px;
    &__info-msg {
      display: flex;
      align-items: center;
      font-size: 13px;
      margin: 0 0 24px;
      color: $blue-5;
      p {
        margin: 0;
        padding: 0;
        line-height: 20px; }
      .icon {
        font-size: 20px;
        color: $blue-5;
        margin-right: 12px; } } }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    .dnd-button {
      &:not(:last-child) {
        margin-right: 8px; } } } }
