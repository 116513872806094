@import 'src/styles/core.sass';

.dnd-combobox {
  &__loading {
    padding: 0 8px;
    font-size: 12px;
    color: $blue-1; }

  &__button {
    padding: 9px 0 9px 9px; }

  &__list {
    position: absolute;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    box-shadow: $block-shadow;
    display: none;
    &.isOpen {
      display: block; }

    &-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.highlighted {
        background-color: $blue-3; }
      &.selected {
        background-color: $blue-2; } } }

  &__empty-list {
    padding: 5px 10px;
    color: $grey-1; } }
