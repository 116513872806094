@import 'src/styles/core.sass';

.image-modal {
  color: $purple-2;

  &__icons {
    .image-modal__images-wrapper {
      margin-top: 20px;
      background: #fff;
      .dnd-images-content__image-items {
        align-items: center;
        justify-content: space-between; }
      .dnd-image-item.icon {
        border: 1px solid $grey-5;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s all;
        &:hover {
          border-color: $green; }
        img {
          width: 32px;
          height: 32px;
          &:hover {
            outline: none; } } } }
    &__actions {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .dnd-label {
        color: $grey-8;
        font-size: 11px; }
      &__search {
        flex: 1;
        .dnd-input {
          width: 100%; } }
      &__categories,
      &__styles {
        margin-left: 8px;
        width: 230px; } } }

  &__ai-images {
    &__actions {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 24px;
      .dnd-label {
        color: $grey-8;
        font-size: 11px; }
      &__search,
      &__negative_prompt {
        flex: 1;
        margin-right: 8px;
        .dnd-input {
          width: 100%; } }
      &__style {
        width: 140px;
        margin-right: 8px; }
      &__aspect-ratio {
        width: 100px;
        margin-right: 8px; } } }

  &__content {
    display: flex;
    flex-direction: column;
    &.global-images {
      margin-top: 35px; } }


  &__search-box {
    position: relative;
    margin: 16px 8px 8px;
    &:before {
      content: '\e94b';
      position: absolute;
      top: 12px;
      left: 10px;
      font: $font-icons;
      color: $grey-9; }
    input[type="search"] {
      background: #fff;
      border: 1px solid $grey-5;
      font: 400 11px/14px $base-font-family;
      color: $black-1;
      padding: 10px 16px 10px 28px;
      &::placeholder {
        color: $grey-9; }
      &:focus,
      &:hover {
        background: #fff;
        border-color: $grey-1; } } }

  &__unsplash-search {
    margin-top: 35px; }

  &__empty {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center; }

  &__add-image-form {
    height: auto;

    &-wrapper {
      display: flex;
      align-items: center; }

    fieldset {
      height: auto;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0; }

    .image-modal__add-image-input {
      flex: 1 0; }

    .image-modal__add-image-button {
      width: 130px;
      margin-left: 10px;
      .icon {
        margin-left: 8px; } } }

  &__images-wrapper {
    position: relative;
    margin-top: 35px;
    height: 350px;
    overflow-x: auto;
    border-radius: $base-border-radius;
    background-color: $grey-3; }

  &__drop-image-button {
    text-align: center;
    position: relative;
    width: calc(25% - 20px);
    height: 120px;
    line-height: 120px;
    font-size: 36px;
    margin: 10px;
    padding: 0;
    border-radius: $base-border-radius;
    color: inherit;
    border: 2px dashed currentColor;
    cursor: pointer;
    transition: 0.3s all ease;
    background-color: transparent;
    &.hidden {
      opacity: 0; }
    &:hover, &:focus {
      opacity: 0.7;
      background-color: rgba($blue-1, 0.3);
      border-color: $blue-1;
      outline: none; } }

  &__drop-message {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease;
    border-radius: $base-border-radius;
    border: 2px solid transparent;
    &.visible {
      opacity: 1;
      visibility: visible; }
    &.active {
      opacity: 0.7;
      visibility: visible;
      background-color: rgba($blue-1, 0.3);
      border-color: $blue-1; }
    .icon {
      font-size: 48px; } } }
