@import 'src/styles/core.sass';

.page-settings-modal {
  &__fonts {
    font-size: 14px;
    &__selected {
      margin-top: 24px;
      font-size: 12px;
      &__table-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        margin: 8px 0 0;
        padding: 0;
        &__nav {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            font-size: 10px;
            background: none;
            color: $purple-2;
            padding: 4px 8px;
            margin: 0;
            border-radius: $base-border-radius;
            &.active {
              background: $black;
              color: #fff; }
            &:first-child,
            &:last-child {
              font-size: 14px; }
            &:disabled {
              background: none; }
            &:hover:not(.active),
            &:focus:not(.active) {
              background: none;
              color: $black; } } } }
      &__table-wrapper {
        border: 1px solid $grey-2;
        border-radius: $base-border-radius;
        position: relative;
        & > .icon-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 16px;
          color: #fff; }
        &.table-loading {
          user-select: none;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0, 0.3); } } }

      table {
        text-align: left;
        width: 100%;
        border-collapse: collapse; }
      table th {
        color: $grey-9;
        font-weight: normal;
        padding: 8px 16px;
        background: $grey-3;
        border-bottom: 1px solid $grey-6;
        &:first-child {
          border-top-left-radius: $base-border-radius; }
        &:last-child {
          border-top-right-radius: $base-border-radius; } }
      table td {
        padding: 6px 16px;
        font-size: 11px;
        &.no-items {
          padding: 24px;
          text-align: center;
          color: $grey-9; }
        button {
          padding: 0;
          margin: 0;
          background: transparent;
          font-size: 12px;
          transition: opacity 0.5s;
          color: $red;
          &:hover {
            opacity: 0.8; } }
        &:last-child {
          width: 40px; } }
      table tr {
        transition: all 0.5s;
        &:hover {
          color: $black-1; }
        &:nth-child(even) {
          background: $grey-3; }
        &:last-child td {
          &:first-child {
            border-bottom-left-radius: $base-border-radius; }
          &:last-child {
            border-bottom-right-radius: $base-border-radius; } } } }
    &__filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      & > * {
        width: 32%; } }
    &__list {
      margin: 8px 0 0;
      border-radius: $base-border-radius;
      background: $grey-5;
      overflow: hidden;
      & [role="rowgroup"] {
        overflow: visible !important; }
      &__inner-list {
        height: 300px;
        padding: 16px;
        &.loading {
          display: flex;
          align-items: center;
          justify-content: center; } }
      &__item {
        margin-bottom: 8px;
        &.selected-item {
          position: relative;
          box-shadow: rgba(0, 0, 0, 0.3) 0 30px 20px -20px;
          z-index: 2; }
        &:last-child {
          margin-bottom: 0; }
        &__font {
          &__preview {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            background: #fff;
            border-top-left-radius: $base-border-radius;
            border-top-right-radius: $base-border-radius;
            font-size: 20px;
            padding: 8px 16px;
            box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;
            .icon-check {
              color: $green;
              font-size: 16px; } }
          &__details {
            padding: 8px 16px;
            background: $grey-3;
            border-bottom-left-radius: $base-border-radius;
            border-bottom-right-radius: $base-border-radius;
            &__actions {
              padding-top: 8px;
              margin-top: 8px;
              border-top: 1px solid $grey-4;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &__add-btn {
                flex-basis: 6%;
                background: $purple-2 !important;
                font-size: 10px;
                padding: 6px 16px;
                transition: opacity 0.5s;
                &:disabled {
                  background: $grey-2 !important; }
                &:hover:not([disabled]) {
                  opacity: 0.8; } }
              &__variants,
              &__subsets {
                flex-basis: 47%;
                & > label {
                  margin-bottom: 8px; }
                label {
                  font-size: 10px;
                  text-transform: capitalize; }
                .dnd-form-control {
                  margin-right: 12px;
                  &:last-of-type {
                    margin-right: 0; }
                  .dnd-label {
                    margin: 0; }
                  .dnd-checkbox {
                    &__icon-wrapper {
                      width: 16px;
                      height: 16px;
                      margin-right: 6px;
                      background: #fff; } } } } }
            &__info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              button {
                background: $grey-7 !important;
                font-size: 10px;
                padding: 6px 16px;
                transition: opacity 0.5s;
                &:hover {
                  opacity: 0.8; } }
              &__name {
                span {
                  display: inline-block;
                  font-size: 12px;
                  &:last-child {
                    margin-left: 8px;
                    font-size: 10px;
                    color: $grey-9; } } } } } } } } } }
