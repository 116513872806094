html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

#root {
  textarea {
    min-height: 160px; }
  .modal-foreground {
    height: 100%;
    width: 100%;
    border-radius: $base-border-radius;
    padding: 0; }
  .modal-background {
    z-index: 10;
    .modal-close {
      font-weight: bold;
      z-index: 1;
      font-size: $font-size-xl;
      line-height: 1em; } }
  .close-modal {
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    position: absolute;
    z-index: 10; } }

// Data animation
@keyframes enterFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
