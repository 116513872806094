@import 'src/styles/core.sass';

.custom-code-modal {
  #code-mirror-search-tip {
    dt {
      font-family: monospace;
      margin-block-end: 8px; }
    dd {
      display: block;
      margin-inline-start: 20px;
      margin-block-end: 16px;
      color: $black; } }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px; }

  &__editor-wrapper {
    width: 100%; }

  &__editor {
    font-size: 16px;
    @include code-mirror-search; }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px; }

  &__button {
    &:not(:last-child) {
      margin-right: 8px; } } }
