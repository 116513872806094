@import 'src/styles/core.sass';

.code-import-modal {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px; }

  &__editor-wrapper {
    width: 48%; }

  &__editor {
    font-size: 13px;
    @include code-mirror-search; }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }

  &__button {
    &:not(:last-child) {
      margin-right: 8px; } } }

