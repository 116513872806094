@import 'src/styles/core.sass';

.view-code-modal {
  #code-mirror-search-tip {
    dt {
      font-family: monospace;
      margin-block-end: 8px; }
    dd {
      display: block;
      margin-inline-start: 20px;
      margin-block-end: 16px;
      color: $black; } }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px; }

  &__editor-wrapper {
    width: 48%;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      label.dnd-label {
        font-size: 14px; } }
    .icon {
      font-size: 13px;
      margin-left: 4px; }
    &__clipboard-buttons {
      button.dnd-button-base.contained {
        font-size: 12px;
        border: 1px solid $black;
        color: $black;
        background: transparent;
        padding: 5px 12px;
        margin-left: 8px; } } }

  &__editor {
    font-size: 13px;
    @include code-mirror-search; } }
