@import 'src/styles/core.sass';

.dnd-select-wrapper {
  position: relative;
  width: auto;
  &::before {
    @include pseudo-expand-more;
    line-height: 1;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none; } }

.dnd-select__select {
  width: 100%;
  border-radius: $base-border-radius;
  border: 1px solid $grey-5;
  background-color: transparent;
  font: 400 12px/14px $base-font-family;
  margin: 0;
  padding: 10px;
  transition: none;
  color: $grey-1;
  text-transform: capitalize;
  appearance: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    outline: 0; }

  option {
    text-transform: capitalize; }

  &:disabled {
    opacity: 0.7; }

  &.dnd-error {
    border-color: $red; } }
