@import 'src/styles/core.sass';

.dnd-cropper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__image-wrapper {
    overflow: hidden;
    flex-shrink: 0; }

  &__image {
    width: 100%;
    height: 100%; }

  &__editor-wrapper {
    position: relative;
    font-size: 0;
    &::after, &::before {
      content: '';
      position: absolute;
      border: 1px solid rgba(#2699FB, 0.3);
      pointer-events: none; }
    &::before {
      top: 0;
      bottom: 0;
      left: 33.33%;
      right: 33.33%;
      border-top: 0;
      border-bottom: 0; }
    &::after {
      top: 33.33%;
      bottom: 33.33%;
      left: 0;
      right: 0;
      border-left: 0;
      border-right: 0; } }

  &__controls {
    margin-left: 30px;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    min-height: 100%; }

  &__label {
    color: $black;
    margin-bottom: 8px; }

  &__helper-text {
    margin-bottom: 8px;
    flex-grow: 1;
    color: $grey-1; }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dnd-button {
      &__label {
        .icon {
          margin-left: auto; } }
      &:not(:last-child) {
        margin-right: 8px; } } } }
