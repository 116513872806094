@import 'src/styles/core.sass';

@keyframes scroll {
  0% {
    transform: translateY(0); }
  30% {
    transform: translateY(100px); } }

.mouse-scroll {
    display: flex;
    align-items: center;
    user-select: none;
    margin: 0 0 16px 283px;
    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      color: $black;
      &:first-child {
        font-weight: bold; }
      &:last-child {
        font-size: 10px; } }
    svg {
      margin-right: 8px;
      path {
        stroke: $purple-2; }
      #mouse-scroll__wheel {
        animation: scroll ease 1.5s infinite;
        stroke: $purple-1; } } }
