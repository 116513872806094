@import 'src/styles/core';

.bulk-actions {
    width: 100%;
    display: flex;
    flex: 1;
    font-size: 12px;
    &__bulk-button {
        padding: 8px 12px;
        font-size: 12px;
        background: $purple-2 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $base-border-radius;
        &.admin-action {
            background-color: $gold !important; }
        .icon {
            margin-right: 8px; }
        &:only-child {
            margin-left: 16px; }
        &:hover {
            opacity: 0.8; } }
    &__actions {
        width: 100%;
        margin-left: 16px;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        &__buttons {
            display: flex;
            align-items: center;
            button {
                margin-left: 6px;
                border-width: 1px;
                border-style: solid;
                padding: 5px 8px;
                background: transparent !important;
                border-radius: $base-border-radius; }
            &:hover {
                opacity: 0.8; }
            &__unpublish {
                color: $grey-8;
                border-color: $grey-8; }
            &__remove,
            &__delete-forever {
                border-color: $red;
                color: $red; } } } }
