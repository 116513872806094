@import 'src/styles/core.sass';

.template-info-modal {
  .dnd-form-control {
    margin-bottom: 16px; }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px; } }
