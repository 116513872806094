@import 'src/styles/core.sass';

.dnd-list {
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  margin: 0;
  font: 400 12px/12px "Source Sans Pro", sans-serif;

  &__item {
    padding: 5px 10px;
    font-size: 12px; } }
