@import 'src/styles/core.sass';
$light-theme-right-panel-color: $black;
$right-panel-control-border-color: $grey-6;

#layout {
  position: relative;
  min-height: 100%;
  scrollbar-width: thin;
  .alert-info {
    color: $blue-5;
    background-color: $blue-6;
    position: relative;
    padding: 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__content {
      display: flex;
      align-items: center; }
    button {
      background: none;
      text-decoration: underline;
      padding: 0;
      margin: 0 0 0 8px;
      font-size: 14px;
      font-weight: bold;
      color: $blue-5; }
    .icon-info {
      margin-right: 10px; }
    .icon-close {
      cursor: pointer; } }
  .title-tooltip {
    max-width: 400px;
    font-size: 11px;
    line-height: 18px;
    padding: 2px 8px;
    font-weight: normal;
    background: $blue-4 !important;
    &:after {
      border-bottom-color: $blue-4 !important; } }
  .gjs-layer-vis {
    padding: 0;
    top: 10px;
    left: 6px; }
  .gjs-layer-move {
    padding: 0;
    top: 9px;
    right: 6px; }
  .gjs-layer-count {
    top: 8px;
    font-size: 11px; }
  .gjs-layer-caret {
    padding: 0;
    left: -16px;
    top: 0;
    &.gjs-layer-open {
      top: -1px; } }
  .gjs-layer-name {
    display: flex;
    align-items: center;
    justify-content: center; }
  .gjs-layer-title {
    padding-top: 6px;
    padding-bottom: 6px; }
  &.dark-theme {
    @include scrollbar(.6em, $blue, rgba($dark-bg, 0.5));
    scrollbar-color: $blue rgba($dark-bg, 0.5);
    .logo {
      background-image: url('/images/logos/light_page.png'); }
    .home {
      #menu {
        background-color: $dark-bg;
        color: #fff;
        #interface-switcher {
          .switcher {
            border: 1px solid rgba(#000, 0.6);
            .toggle {
              border: 1px solid $black;
              background-color: #000;
              &:before {
                background-color: $red;
                left: 5px; } } } } }
      .templates-wrapper {
        color: #fff;
        background-color: #343543; } }
    #editor {
      .gjs-field-select {
        background-color: #fff;
        color: $black;
        .gjs-d-s-arrow {
          border-top-color: $black; }
        select option {
          background-color: #fff;
          color: $black; } }
      .gjs-field-checkbox {
        background-color: #fff;
        i {
          border-color: $black; } }
      .gjs-two-color {
        color: #fff; }
      .gjs-four-color {
        color: $blue; }
      .gjs-one-bg {
        background-color: $dark-bg;
        &.gjs-editor {
          background-color: $bg-color; } }
      .gjs-three-bg {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        background-color: $blue; }
      .gjs-block:before, svg {
        background-color: $blue; }
      .gjs-pn-views-container {
        .gjs-traits-label, .gjs-trt-traits, .gjs-trt-header {
          border-color: $black;
          background-color: darken($dark-bg, 20%);
          input {
            background-color: #fff;
            color: $dark-bg; } }
        .gjs-clm-tags {
          padding: 0;
          border-color: tint($dark-bg, 10%);
          .gjs-field {
            background-color: darken($dark-bg, 50%); }
          #gjs-clm-tags-field {
            background-color: tint($dark-bg, 10%);
            display: flex;
            align-items: center;
            span {
              background: inherit;
              box-shadow: none;
              border: none; } }
          #gjs-clm-sel {
            color: $orange;
            font-weight: bold; }
          #gjs-clm-tags-c {
            display: inline-flex; }
          #gjs-clm-add-tag.fa {
            border-color: $black;
            background-color: $blue;
            color: #fff; } }
        .gjs-sm-header {
          border-color: $black;
          background-color: darken($dark-bg, 20%); }
        .gjs-sm-sectors {
          color: #fff;
          .gjs-sm-title, .gjs-sm-sector {
            border: none;
            border-color: $black;
            background-color: darken($dark-bg, 20%); }
          .gjs-sm-properties {
            background-color: darken($dark-bg, 4%);
            border-color: tint($dark-bg, 10%);
            .gjs-sm-btn {
              background-color: $blue;
              color: #fff; }
            select, input[type="text"] {
              background-color: black;
              color: #fff; }
            .gjs-radio-items {
              background-color: darken($dark-bg, 4%); }
            .gjs-radio-item, .gjs-select {
              background-color: darken($dark-bg, 4%);
              border-color: $black;
              input:checked + .gjs-radio-item-label {
                color: $orange; } }
            .gjs-radio-item-label {
              background-color: darken($dark-bg, 4%); } } }
        .gjs-layer {
          &.gjs-selected {
            .gjs-layer-title {
              background-color: tint($dark-bg, 10%);
              color: #fff; } }
          &.gjs-hovered {
            .gjs-layer-title {
              background-color: darken($dark-bg, 50%); } }
          .gjs-layer-title {
            border-color: $black;
            color: $blue;
            background-color: darken($dark-bg, 20%); } } } } }

  &.light-theme {
    @include scrollbar(.6em, rgba($grey-1, 0.35), rgba($grey-1, 0.15));
    scrollbar-color: rgba($grey-1, 0.35) rgba($grey-1, 0.15);
    color: $light-theme-font-color;

    .home {
      background: url("/images/backgrounds/background.svg") top right / cover no-repeat $bg-color;
      #menu {
        #interface-switcher {
          .switcher {
            border: 1px solid tint($orange, 75%);
            .toggle {
              border: 1px solid tint($orange, 65%);
              background-color: tint($orange, 90%);
              &:before {
                background-color: $orange;
                left: calc(100% - 24px); } }
            .icon {
              color: $orange; } } } }
      .templates-wrapper {
        color: $editor-right-panel-label-color; } }
    #editor {
      &.shared-temp-current-user {
        .editor-content__panel-blocks-tabs {
          .gjs-pn-buttons {
            span:nth-of-type(3) {
              display: none; } } } }
      .gjs-field-select {
        background-color: #fff;
        color: $light-theme-font-color;
        .gjs-d-s-arrow {
          border-top-color: tint($orange, 70%); }
        select option {
          background-color: #fff;
          color: $light-theme-font-color; } }
      .gjs-field-checkbox {
        background-color: #fff;
        i {
          border-color: $light-theme-font-color; } }

      .editor-content {
          &__panel-zoom-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__view {
              margin-right: 10px;
              font-size: 14px;
              width: 30px; }
            .icon {
              &:before {
                font-size: 16px; } } } }
      .gjs-two-color {
        color: $light-theme-right-panel-color; }
      .gjs-one-bg {
        background-color: #fff;
        &.gjs-editor {
          background-color: $bg-color; } }
      .gjs-three-bg {
        align-items: center;
        background-color: $purple-2;
        display: flex;
        flex-direction: row;
        padding: 0;
        color: #fff; }
      .gjs-block-category.gjs-open {
        border: none; }
      .gjs-pn-views-container {
        .gjs-traits-label, .gjs-trt-traits, .gjs-trt-header {
          border-color: #fff;
          input {
            background-color: #fff; } }
        .gjs-trt-traits:empty {
          display: none; }
        .gjs-traits-label, .gjs-trt-traits {
          background-color: $grey-3;
          color: $editor-right-panel-label-color; }
        .gjs-trt-header {
          font-weight: 400;
          padding: 0; }
        .gjs-field-full-width button {
          background-color: $blue-1;
          box-shadow: 0 3px 6px #00000029;
          @include event {
            box-shadow: none; } }
        .gjs-field-arrow-u {
          border-bottom: 4px solid darken(saturate(adjust-hue($bg-color, 205), 11.66), 22.16); }
        .gjs-field-arrow-d, .gjs-d-s-arrow {
          border-top: 4px solid darken(saturate(adjust-hue($bg-color, 205), 11.66), 22.16); }
        .gjs-clm-sel {
          font-weight: 400;
          color: $light-theme-right-panel-color; }
        .gjs-clm-tags {
          background-color: $grey-3;
          color: $black;
          &__header {
            background: $grey-3;
            width: 100%;
            text-transform: uppercase;
            font-size: 11px;
            border-top: 1px solid $grey-4;
            border-bottom: 1px solid $grey-4;
            padding: 11px 16px;
            display: flex;
            align-items: center;
            font-weight: bold;
            &:before {
              content: "\e90d";
              font-family: 'icons';
              font-size: 16px;
              color: $purple-1;
              margin-right: 10px; } }
          #gjs-clm-up {
            padding: 0 16px; }
          #gjs-clm-tags-field {
            display: flex;
            align-items: center;
            padding: 5px 16px;
            margin: 0;
            #gjs-clm-new {
              margin-top: 5px; }
            input, .fa, #gjs-clm-close {
              color: $black; } }
          #gjs-clm-tag-label {
            display: inline-block;
            padding: 0 3px; }
          #gjs-clm-add-tag {
            margin-bottom: 3px; }
          #gjs-clm-tags-c {
            display: inline-flex; } }
        .gjs-sm-header {
          border-color: #fff;
          background-color: tint($orange, 94%); }
        .gjs-sm-sectors {
          color: $light-theme-font-color;
          .gjs-sm-sector {
            border-color: transparent;
            i:before {
              color: rgba($light-theme-font-color, 0.4); } }
          .gjs-sm-properties {
            font-size: 10px;
            .gjs-sm-field {
              background-color: transparent;
              border: 1px solid $grey-2; }
            .gjs-sm-layers {
              background-color: #fff;
              #gjs-sm-add {
                color: $light-theme-font-color; }
              .gjs-sm-btn {
                background-color: $purple-2;
                color: #fff; } }
            .gjs-field-radio {
              background-color: transparent; }
            .gjs-select {
              background: transparent; }
            .gjs-radio-item {
              background: #fff;
              color: $editor-right-panel-label-color;
              &:before {
                color: $grey-7; }
              input:checked + .gjs-radio-item-label {
                background-color: $editor-font-color;
                box-shadow: none;
                color: #fff;
                &:before {
                  color: #fff; } } } } }
        .gjs-d-s-arrow {
          border-top-color: darken(saturate(adjust-hue($bg-color, 205), 11.66), 22.16); }
        .gjs-sm-label {
          color: $editor-right-panel-label-color; }
        .gjs-layer {
          &.gjs-selected {
            .gjs-layer-title {
              background-color: lighten(saturate($bg-color, 4.76), 2.69); } }
          &.gjs-hovered {
            .gjs-layer-title {
              background-color: lighten(saturate($bg-color, 4.76), 3.69); } }
          .gjs-layer-title {
            border-color: #fff;
            background-color: lighten(saturate($bg-color, 4.76), 5.69); } }
        #gjs-input-holder {
          color: $grey-2; }
        .gjs-field select:not(.gjs-input-unit), .gjs-field:not(.gjs-field-range) input, textarea {
          background: #fff;
          border: 1px solid $right-panel-control-border-color;
          color: $light-theme-font-color;
          &::placeholder {
            color: rgba($light-theme-font-color, .6); } }
        .gjs-field-units {
          border-bottom: 1px solid $right-panel-control-border-color;
          border-top: 1px solid $right-panel-control-border-color; }
        .gjs-field-colorp {
          border-left-color: $right-panel-control-border-color; }
        .gjs-clm-tag-status, .gjs-clm-tag-close, .gjs-clm-tags-btn__add {
          background-color: $purple-2;
          border: none;
          svg {
            background-color: inherit;
            path {
              fill: #fff; } } } } } } }
