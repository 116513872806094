@import 'src/styles/core.sass';

.dnd-form-helper-text {
  color: $black-2;
  font-size: 11px;
  font-family: $base-font-family;
  letter-spacing: 0;
  margin-top: 3px;

  &.error {
    color: $red; } }
