@import 'src/styles/core.sass';

.personal-notes-modal {
    &__sub-title {
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__delete-all-button {
            background-color: $red !important;
            color: #fff !important;
            padding: 3px 6px;
            margin: 0;
            font-size: 11px;
            transition: all 0.5s;
            &:hover {
                opacity: 0.7; } } }
    &__no-content {
        overflow-y: hidden !important; }
    &__content {
        margin: 12px 0;
        overflow-y: scroll;
        max-height: 250px;
        &__note {
            position: relative;
            background: $grey-3;
            border-radius: $base-border-radius;
            margin: 0 5px 8px 0;
            padding: 12px 50px 12px 15px;
            &__delete-button {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                cursor: pointer;
                transition: all 0.5s;
                &:hover {
                    opacity: 0.7; }
                .icon {
                    color: $red; } }
            &__sender-name {
                background: $blue-1;
                padding: 0 4px;
                border-radius: $base-border-radius;
                color: #fff;
                font-size: 11px;
                margin-right: 4px;
                text-transform: capitalize;
                &.sender-shared {
                    background-color: $green; } }
            time {
                font-size: 10px;
                display: inline-block;
                color: $grey-7; }
            p {
                font-size: 12px;
                padding: 0;
                margin: 6px 0 0 0;
                color: $black-1; } }
        &__no-notes {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $grey-3;
            color: $grey-2;
            border-radius: $base-border-radius;
            font-size: 13px;
            height: 250px;
            span {
                font-size: 54px;
                color: $grey-2;
                margin-bottom: 10px; } } }
    &__adding {
        position: relative;
        margin-bottom: 22px;
        .dnd-button-base.contained[type="submit"] {
            position: absolute;
            top: 5px;
            right: 5px;
            box-shadow: none;
            background: $blue-1;
            z-index: 1;
            padding: 8px 25px; }
        input[type="text"] {
            padding: 12px 80px 12px 12px; } } }
