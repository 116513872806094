@import 'src/styles/core.sass';

.ReactModal__Content {
  padding: 0 !important; }

.base-modal {
  &__content-wrapper {
    padding: 0 26px 26px;
    button.dnd-button-base {
      &.contained {
        background: $black; }
      &.text {
        color: $black-1; } } } }
